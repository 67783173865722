const cn = {
  message: {
    switchLang: "选择语言",
    home: "首页",
    about: "关于我们",
    product: "产品与服务",
    productTitle1: "定制化研发服务",
    productTitle2: "产品",
    // productTitle1:'定制化研发服务',

    news: "新闻",
    contact: "联系我们",
    navigation: "导航",
    support: "支持",
    // placeholderInNav: "输入关键词",

    name: "智臻寰宇",
    next: "下一篇",
    up: "上一篇",

    slogan: "创造和利用高新技术让人类生活更美好。",

    // info1: `“我们是领先的物联网和人工智能解决方案提供商。”`,
    // info2: `智臻寰宇高科技（深圳）有限公司以物联网为基础载体，将人工智能算
    // 法和嵌入式系统开发紧密结合应用。在物联网基础功能上，开发轻量级的深度学习算法，实现云端和边缘端的快速推理，可广泛
    // 应用于各种智能化应用领域，例如：工业物联网、医疗健康、企业应用、可穿戴设备等。 `,

    // whatWeDo1: `软硬固件终端移动端云端全栈开发`,
    // whatWeDo2: `标品和企业定制化开发`,
    // whatWeDo3: `从电路设计、仿真、PCB Layout、外观结构设计到生产交付出货`,

    // productHead: `我们的产品 `,

    // applicationScenarios: `产品应用场景`,
  },
  contact: {
    title: `联系我们`,
    email: "chinali80{'@'}163.com",
    phone: "13480945504",
    location: `深圳市宝安区西乡街道桃源社区华丰SOHO创意世界A栋409`,
    wechat: `微信公众号`,
    Postcode: `518101`,
    locationTitle: "地址",
    phoneTitle: "手机",
    PostcodeTitle: "邮编",
    emailTitle: "邮箱",
  },
  footer: {
    copyright: "智臻寰宇高科技（深圳）有限公司",
    version: "版本号",
  },
  about: {
    info: `<p>智臻寰宇高科技（深圳）有限公司于2020年5月6日创立，位于中国经济最具有活力的城市——深圳，是领先的智慧物联网和人工智能解决方案提供商和制造商。公司专注于高新技术的研发，致力于为客户和社会创造更大的价值。</p>
    <p>公司以物联网为基础载体，将硬件电路设计、嵌入式软件开发、人工智能算法、边缘计算、机器视觉、大数据分析、云计算和数字孪生紧密结合开发出最佳的解决方案并提供量产产品，可广泛应用于各行各业，例如：智慧电网、电子测量、数字化升级、工业物联网、智能运维、医疗健康、智慧农业、冷链物流、可穿戴设备等。</p>
    <p>智臻寰宇具备软件、硬件、固件，终端、边缘端和云端全栈开发能力，提供标品和定制化研发服务。智臻寰宇的产品和技术方案都采用行业最先进的技术并且锐意创新，力求用自主创新的核心技术引领公司的发展。公司和行业内多家顶级供应商建立正式的合作关系，拥有稳定的供应链，具备从研发到生产制造的全产业链项目交付能力。</p>
    `,
    bigEvent: `
    <p>公司大事记：</p>
    <p>1、2020 年11 月11 日至15 日参展第二十二届中国国际高新技术成果交易会。</p>
    <p>2、2021 年12 月27 日至29 日参展第二十三届中国国际高新技术成果交易会。</p>
    <p>3、2022年3月9日，智臻寰宇高科技（深圳）有限公司赢得了国内某大型电
    力企业的智能设备研发服务招标项目。</p>
    <p>4、2022年11月15日参加第二十四届中国国际高新技术成果交易会慕尼黑华
    南电子展国际嵌入式系统创新论坛，发表演讲：“智慧物联网解决方案、前沿技
    术和未来发展趋势”。</p>`,
    bigEventTitle: `大事记`,
    jianjie: `公司简介`,
  },
  support: {
    title: "支持",
    title2: "产品手册",
    table: {
      title: "标题",
      version: "版本",
      publish_time: "发布日期",
      download: "下载",
      name: "智臻寰宇2024年产品手册",
    },
  },
  download: "下载产品资料",
  news: {
    banner: {
      title: "最新资讯",
      slogan: "了解我们的最新动态",
    },
    publish: "发表时间",
  },
  home: {
    section1: {
      title: "定制化服务",
      item1: "电路设计",
      item2: "嵌入式软件开发",
      item3: "桌面型软件开发",
      item4: "前端开发",
      item5: "数据分析",
    },
    section2: {
      title: "产品",
      item1: "微型嵌入式人工智能核心板",
      item2: "星云二号系列智慧终端",
      item3: "智慧物联网后台管理系统",
      item4: "数字天工-智慧工厂",
    },
    section3: {
      title: "应用场景",
      item1: "智慧电网",
      item2: "数字化升级",
      item3: "智慧医疗",
      item4: "智能工厂",
      item5: "可穿戴设备",
    },
  },
  navMenu: {
    support: {
      title: "支持",
      child: {
        title: "资料下载",
        child: {
          title: "产品手册",
        },
      },
    },
    serive: {
      title: "定制化研发服务",
      child1: {
        title: "电子产品研发服务",
        child: {
          title1: "电路设计",
          title2: "嵌入式软件开发",
          title3: "机械设计",
        },
      },
      child2: {
        title: "应用软件开发",
        child: {
          title1: "桌面应用软件开发",
        },
      },
      child3: {
        title: "前端开发",
        child: {
          title1: "前端开发",
        },
      },
      child4: {
        title: "数据分析",
      },
      child5: {
        title: "边缘计算网关",
      },
    },
    product: {
      title: "产品",
      child1: {
        title: "硬件",
        child: {
          title1: "微型嵌入式人工智能核心板",
          title2: "小型物联网终端板",
          title3: "小型4G终端",
          title4: "微型NB-IoT终端",
          title5: "微型LoRa终端",
          title6: '"机遇号"物联网终端核心板',
          title7: "LoRa-4G网关",
        },
      },
      child2: {
        title: "云端软件",
        child: {
          title1: "智慧物联网后台管理系统",
        },
      },
      child3: {
        title: "数字孪生",
        child: {
          title1: "数字天工-智慧工厂",
        },
      },
      child4: {
        title: "机器视觉",
        child: {
          title1: "安防应用",
          title2: "工业检测",
        },
      },
    },
  },
  banner: {
    ele: {
      title: "一站式定制化电子产品设计服务(EDS)",
      desc: "整机设计  {'|'} 部件设计  {'|'} 设计变更",
    },
    app: {
      title: "应用软件开发",
      // desc: "整机设计 | 部件设计 | 设计变更",
    },
    frontend: {
      title: "前端开发",
    },
    software: {
      title: "云端软件",
    },
    digtal: {
      title: "数字孪生",
    },
    hardware: {
      title: "硬件",
      core: {
        input: "供电",
        size: "模块尺寸",
        Core: "内核",
        FLASH: "FLASH",
        RAM: "RAM/SRAM",
        Fre: "最高主频",
      },
      netpune: {
        input: "供电",
        chip: "模块型号",
        SRAM: "SRAM",
        FLASH: "SPI FLASH",
        device1: "温湿度传感器",
        device2: "六轴加速度",
      },
      fourG: {
        input: "供电",
        size: "外观尺寸",
        RAM: "RAM",
        ROM: "ROM",
        file: "文件系统",
      },
      nbiot: {
        input: "供电",
        fre: "频段",
        size: "外观尺寸",
        core: "内核",
        flash: "FLASH",
        RAM: "RAM | SRAM",
        max: "最高主频",
        eeprom: "内置EEPROM",
      },
      lora: {
        input: "供电",
        size: "外观尺寸",
        core: "内核",
        flash: "FLASH",
        RAM: "RAM | SRAM",
        max: "最高主频",
        eeprom: "内置EEPROM",
      },
      gateway: {
        flash: "FLASH",
        fre: "LoRa频段",
      },
    },
    vision: {
      title: "机器视觉",
    },
    data: {
      title: "数据分析",
    },
  },
  linkTab: {
    ele: {
      title: "电子产品研发服务",
    },
  },
};

export default cn;
