import { prefix } from "./newsdata";
export var productData = [
  {
    id: 1,
    title: "微型嵌入式人工智能核心板",
    keyFeatures: ["Cube.AI", "丰富的接口", "小尺寸"],
    features: [
      {
        info: "Cortex-M4系列",
        subInfo: [],
      },
      {
        info: "支持Keras,TFLite,ONNX等深度学习框架",
        subInfo: [],
      },
      {
        info: "板载精准的基准源0.5%,VREF= 1.24V",
        subInfo: [],
      },
      {
        info: "支持FATFS文件系统",
        subInfo: [],
      },
      {
        info: "支持实时操作系统FreeRTOS,RT-Thread,zephyr",
        subInfo: [],
      },
      {
        info: "抗干扰能力强",
        subInfo: [],
      },
      {
        info: "支持RS-485,RS-232,I2C,USART,SPI,USB,I2S等总线",
        subInfo: [],
      },
      {
        info: "支持随机数生成",
        subInfo: [],
      },
    ],
    name: "coreBoard",
    details: [
      {
        input: `<div>通过Vin引脚供电,+5~12V</div>`,
        // model: `STM32G431CBT6`,
        // size: "43.2 mm * 19.06 mm",
        core: `ARM Cortex-M4`,
        flash: "128KB",
        RAM: "32KB | 22KB",
        max_freq: "170MHz",
        pn: "<div>WAU2023022301 | </div><div>WAU2023022301F</div>",
      },
    ],
    img: [
      {
        src: prefix + "3c2b6713-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "ba14e59a-cff2-4627-99a5-19310964837b-01-cn",
      },
    },
  },
  {
    id: "4g",
    title: "LoRa-4G网关",
    keyFeatures: [
      "小型75mm × 50mm",
      "MQTT云端互连",
      "OpenCPU",
      "LoRa低频和高频",
      "超低功耗设计",
      "LoRa组网 ",
    ],
    features: [
      {
        info: "本地和云端灵活配置",
        subInfo: [],
      },
      {
        info: "定位：北斗/GPS/GLONASS/QZSS",
        subInfo: [],
      },
      {
        info: "内嵌实时操作系统",
        subInfo: [],
      },
      {
        info: "支持RS-485、RS-232、UART、I2C、SPI、USB2.0、模拟音频、数字音频、ADC",
        subInfo: [],
      },
      {
        info: "支持固件空中升级",
        subInfo: [],
      },
      {
        info: "支持屏幕显示",
        subInfo: [],
      },
      {
        info: "支持SD卡本地存储",
        subInfo: [],
      },
    ],
    name: "gateway",
    details: [
      {
        input: `<div>方式一：5～12V供电</div><div>方式二：锂电池供电</div>`,
        size: "75mm × 50mm × 1.6mm",
        flash: "8MB",
        fre: "470M～510MHz和860～930MHz",
      },
    ],
    img: [
      {
        src: prefix + "3c2bwe13-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "ba14e59a-cff2-6666-99a5-19310964837b-01-cn",
      },
    },
  },
  {
    id: 2,
    title: "小型物联网终端板",
    keyFeatures: [
      "支持WIFI和蓝牙",
      "内置六轴加速度和温湿度传感器",
      "支持USB Type-C",
    ],
    features: [
      {
        info: "主模块ESP32系列",
        subInfo: [],
      },
      {
        info: "支持UART,I2C,SPI等多种总线协议",
        subInfo: [],
      },
      {
        info: "支持OTA",
        subInfo: [],
      },
      {
        info: "支持通过USB,Type-C接口烧录程序",
        subInfo: [],
      },
      {
        info: "丰富的GPIO接口,共26个",
        subInfo: [],
      },
      {
        info: "可用于Web Server",
        subInfo: [],
      },
      {
        info: "支持实时操作系统FreeRTOS",
        subInfo: [],
      },
      {
        info: "良好的PCB设计使得天线发射和接收性能最佳",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [
      {
        input: `<div>方式一:USB Type-C供电</div>   <div>方式二:Vin引脚5V供电</div> `,
        // model: `ESP32-WROOM-32`,
        flash: "4MB",
        SRAM: "520KB",
        pn: "WAU2022120901 | WAU2022120901F",
      },
    ],
    img: [
      {
        src: prefix + "a9c6405d-d11e-438b-8ab7-c29be2cfa51c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "1c98b919-e5bb-4023-8f66-d77078708372-01-cn",
      },
    },
  },
  {
    id: 10,
    title: '"机遇号"物联网终端核心板',
    keyFeatures: [
      "强大的处理能力",
      "丰富的IO接口(136pins)",
      "内嵌物联网通信板",
    ],
    features: [
      {
        info: "Cortex-M4系列",
        subInfo: [],
      },
      {
        info: "支持Keras,TFLite,ONNX等深度学习框架",
        subInfo: [],
      },
      {
        info: "支持TFT屏幕显示",
        subInfo: [],
      },
      {
        info: "实时操作系统FreeRTOS,RT-Thread,zephyr",
        subInfo: [],
      },
      {
        info: "支持WIFI通信",
        subInfo: [],
      },
      {
        info: "丰富的GPIO接口(136pins)",
        subInfo: [],
      },
      {
        info: "支持RS-485,USART,I2C,SPI,USB等总线",
        subInfo: [],
      },
      {
        info: "7.2Msps ADC",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [
      {
        input: `<div>+3.3V~+12V</div> `,
        // model: `ESP32-WROOM-32`,
        flash: "1MB | 4Gb | 256Mb",
        RAM: "(256KB + 4KB) 256Mb",
        pn: "WAU2022120901 | WAU2022120901F",
        core: "ARM Cortex-M4",
        max_freq: "180MHz",
      },
    ],
    img: [
      {
        src: prefix + "a9c64123-d11e-438b-8ab7-c29be2cfa52a-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "666040b9-1a64-4982-a246-91356ed928b9-01-cn",
      },
    },
  },
  {
    id: 3,
    title: "智慧物联网后台管理系统",
    keyFeatures: ["人工智能", "数字孪生", "增强现实"],
    features: [
      {
        info: "边缘计算和云计算的有机结合,AI算法实现推理和决策",
        subInfo: [],
      },
      {
        info: "云端算法投送,按需动态分配,助力边缘AI",
        subInfo: [],
      },
      {
        info: "数字孪生,支持三维模型的放大、缩小、旋转和投射",
        subInfo: [],
      },
      {
        info: "数据库集群",
        subInfo: [],
      },
      {
        info: "自动化测试优化产品质量",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "c747d053-0baf-425e-8202-a72999054e7e-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "4b663419-4077-479f-9334-bf159509ecac-01-cn",
      },
    },
  },
  {
    id: 4,
    title: "数字天工-智慧工厂",
    keyFeatures: ["虚实结合", "数字演绎", "AI赋能"],
    features: [
      {
        info: "虚实结合",
        subInfo: [],
      },
      {
        info: "数字演绎",
        subInfo: [],
      },
      {
        info: "AI赋能",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "5d654ae2-8877-493e-b47e-be02d9f67a3b-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "379987fc-3663-46b2-a191-22d6d7b517c8-01-cn",
      },
    },
  },
  ///new !
  {
    id: 5,
    title: "小型4G终端",
    keyFeatures: ["小型", "云端互连", "OpenCPU"],
    features: [
      {
        info: "外观尺寸4.5cm × 4.5cm",
        subInfo: [],
      },
      {
        info: "LTE Cat.1",
        subInfo: [],
      },
      {
        info: "支持实时操作系统",
        subInfo: [],
      },
      {
        info: "支持固件空中升级",
        subInfo: [],
      },
      {
        info: "定位：北斗/GPS/GLONASS/QZSS",
        subInfo: [],
      },
      {
        info: "支持RS-485、RS-232、UART、IC、SPI、USB2.0、模拟音频、数字音频",
        subInfo: [],
      },
      {
        info: "频段",
        subInfo: ["LTE-TDD：B34/B38/B39/B40/B41", "LTE-FDD：B1/B3/B5/B8"],
      },
      {
        info: "数据传输",
        subInfo: [
          "LTE-TDD速率：Max. 7.5Mbps(DL)/Max. 1Mbps(UL)",
          "LTE-FDD速率：Max. 10Mbps(DL)/Max. 5Mbps(UL)",
        ],
      },
      {
        info: "网络协议：MQTT / MQTTS / HTTP / HTTPS / TCP / UDP / PPP / FTP / NTP / PING / SSL",
        subInfo: [],
      },
    ],
    name: "小型4G终端",
    details: [
      {
        input: `<div style="text-align:center">5～12V供电</div>`,
        size: `45mm * 45mm * 9mm`,
        RAM: "8MB",
        ROM: "8MB",
        file: "约1408KB",
        pn: "<div>WAU2023051301 |</div> <div></div>WAU2023051302F</div>",
      },
    ],
    img: [
      {
        src: prefix + "52672361-76cf-487a-9889-8f7f80ee1b3c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "163b6afa-a371-49c4-86de-48fafc847536-01-cn",
      },
    },
  },
  {
    id: 6,
    title: "微型NB-IoT终端",
    keyFeatures: ["微型", "云端互连", "超低功耗"],
    features: [
      {
        info: "外观尺寸2.5cm × 2.5cm",
        subInfo: [],
      },
      {
        info: "5G NB-IoT",
        subInfo: [],
      },
      {
        info: "支持Cube.AI",
        subInfo: [],
      },
      {
        info: "支持实时操作系统",
        subInfo: [],
      },
      {
        info: "支持固件空中升级",
        subInfo: [],
      },
      {
        info: "Cortex-M0 +系列",
        subInfo: [],
      },
      {
        info: "支持Keras、TFLite、ONNX等深度学习框架",
        subInfo: [],
      },
      {
        info: "支持RS-485、I2C、SPI、ADC等",
        subInfo: [],
      },
      {
        info: "频段：B5/B8",
        subInfo: [],
      },
      {
        info: "数据传输：Cat NB2速率：126kbps（DL）/ 158.5kbps（UL）",
        subInfo: [],
      },
      {
        info: "网络协议：MQTT / HTTP / TCP / UDP / CoAP / LwM2M / IPV6 / DTLS / DNS",
        subInfo: [],
      },
    ],
    name: "微型NB-IoT终端",
    details: [
      {
        input: `<div>方式一:5V供电</div>   <div>方式二:锂电池供电</div> `,
        fre: "B5/B8",
        size: `25mm * 25mm * 6mm`,
        core: "Cortex-M0+",
        flash: "32KB",
        RAM: "8KB",
        maxPin: "32MHz",
        eeprom: "1KB",
        pn: "WAU2023060601 | WAU2023060602F",
      },
    ],
    img: [
      {
        src: prefix + "1438f644-006e-46b6-b644-5078ae4122c3-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "e701af55-15a7-4aab-bbcf-5c6031412fcc-01-cn",
      },
    },
  },
  {
    id: 7,
    title: "微型LoRa终端",
    keyFeatures: ["微型", "超低功耗", "LoRa组网"],
    features: [
      {
        info: "外观尺寸3.5cm × 3.5cm",
        subInfo: [],
      },
      {
        info: "支持频段470MHz ～ 510MHz",
        subInfo: [],
      },
      {
        info: "支持Cube.AI",
        subInfo: [],
      },
      {
        info: "支持实时操作系统",
        subInfo: [],
      },
      {
        info: "支持固件空中升级",
        subInfo: [],
      },
      {
        info: "Cortex-M0 +",
        subInfo: [],
      },
      {
        info: "支持Keras、TFLite、ONNX等深度学习框架",
        subInfo: [],
      },
      {
        info: "支持RS-485、I C、USART、ADC等",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [
      {
        input: `<div>方式一:5~12V供电</div>   <div>方式二:锂电池供电</div> `,
        size: `35mm × 35mm × 13.7mm`,
        core: "Cortex-M0+",
        flash: "32KB",
        RAM: "8KB",
        maxPin: "32MHz",
        eeprom: "1KB",
        pn: "WAU2023042601 | WAU2023042601F",
      },
    ],
    img: [
      {
        src: prefix + "1e9b6567-f715-4db9-bc4e-4f443ba6e6a8-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "58e893f1-dd71-40a2-8f84-5a11e4c0afee-01-cn",
      },
    },
  },
  // 机器视觉
  {
    id: 8,
    title: "安防应用",
    keyFeatures: [],
    features: [
      {
        info: "安全帽、安全衣检测",
        subInfo: [],
      },
      {
        info: "明火检测",
        subInfo: [],
      },
      {
        info: "人脸识别",
        subInfo: [],
      },
      {
        info: "人形识别",
        subInfo: [],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "46fa6615-ab74-4df3-a6c4-3050338244e6-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "",
      },
    },
  },
  {
    id: 9,
    title: "工业检测",
    keyFeatures: [],
    features: [
      {
        info: "产品外观不良检测",
        subInfo: ["高精度", "稳定", "实时"],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "0e16b67f-853e-4571-9139-123231f4298f-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "",
      },
    },
  },
];

export var serviceData = [
  {
    id: 1,
    title: "电路设计",
    keyFeatures: [],
    features: [
      {
        info: "模拟/数字电路设计,PCB Layout,样品制作,包括：",
        subInfo: [
          "嵌入式系统(含通信模块)",
          "AC/DC电源,DC/DC电源",
          "锂电池储能系统",
        ],
      },
      {
        info: "三维系统建模",
        subInfo: [],
      },
      {
        info: "高性价比,可量产的BOM物料",
        subInfo: [],
      },
      {
        info: "第三方测试认证服务(EMC、Safety和可靠性)",
        subInfo: [],
      },
      {
        info: "高密度,小型化设计",
        subInfo: [],
      },
    ],
    name: "dianlu",
    details: [],
    img: [
      {
        src: prefix + "5b46c30d-4b0b-410b-b824-1281e3112aba-03-xx.jpg",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-cn",
      },
    },
  },
  {
    id: 2,
    title: "嵌入式软件开发",
    keyFeatures: [],
    features: [
      {
        info: "ARM Cortex-M系列(例如:STM32),包括:",
        subInfo: [
          "ADC/DAC",
          "UART,I2C,SPI,CAN,RS-232,RS-485,USB等各种总线通信",
          "实时操作系统FreeRTOS,RT-Thread等",
          "FATFS文件系统",
        ],
      },
      {
        info: "ARM Cortex-A系列",
        subInfo: [],
      },
      {
        info: "电池BMS算法",
        subInfo: [],
      },
      {
        info: "轻量化深度神经网络开发",
        subInfo: [],
      },
      {
        info: "固件空中升级（FOTA）",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [],
    img: [
      {
        src: prefix + "6faa16f6-bf6f-483d-afc1-6c39ef1261a9-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-cn",
      },
    },
  },
  {
    id: 3,
    title: "机械设计",
    keyFeatures: [],
    features: [
      {
        info: "ID设计",
        subInfo: [],
      },
      {
        info: "整机设计",
        subInfo: [],
      },
      {
        info: "2D/3D结构图",
        subInfo: [],
      },
      {
        info: "堆叠",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "c747d053-0baf-425e-8202-a72999054e6e-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-cn",
      },
    },
  },
  {
    id: 4,
    title: "桌面应用软件开发",
    keyFeatures: ["跨平台", "支持多种通信协议BLE、WIFI等", "实时波形图"],
    features: [
      {
        info: "跨平台（Windows、Linux、MacOs）",
        subInfo: [],
      },
      {
        info: "AI算法加持",
        subInfo: [],
      },
      {
        info: "模块自由加减",
        subInfo: [],
      },
      {
        info: "自适应布局",
        subInfo: [],
      },
      {
        info: "支持多种通信协议（BLE、WIFI等）",
        subInfo: [],
      },
      {
        info: "内嵌数据库",
        subInfo: [],
      },
      {
        info: "精美的UI设计",
        subInfo: [],
      },
      {
        info: "自动生成报表（PDF、Word、Excel等）",
        subInfo: [],
      },
      {
        info: "OTA升级",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "777c112b-83ca-4acf-8a98-4947ab5524da-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "91dc746c-3c01-437e-a6e1-e1ddc932d9ce-01-cn",
      },
    },
  },
  {
    id: 5,
    title: "前端开发",
    keyFeatures: ["前端开发", "数据大屏", "专业UI设计"],
    features: [
      {
        info: "实时性",
        subInfo: [],
      },
      {
        info: "全方位",
        subInfo: [],
      },
      {
        info: "GIS定位",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "7d0dd728-23fa-4e01-9e94-234809b8be78-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "a73372b9-7df0-46bc-b989-00131aa87380-01-cn",
      },
    },
  },
  {
    id: 6,
    title: "数据分析",
    keyFeatures: [],
    features: [
      {
        info: "数理统计",
        subInfo: [],
      },
      {
        info: "数据建模",
        subInfo: [],
      },
      {
        info: "数据挖掘",
        subInfo: [],
      },
      {
        info: "机器学习",
        subInfo: [],
      },
      {
        info: "大数据分析",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "4c40bf6d-c04e-4249-a032-e46e211bab3c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "a73372b9-7df0-46bc-b989-00131aa87380-01-cn",
      },
    },
  },
  {
    id: 7,
    title: "边缘计算网关",
    keyFeatures: ["AI计算", "边云协同", "高可靠性"],
    features: [
      {
        info: "AI算力",
        subInfo: [],
      },
      {
        info: "Linux系统",
        subInfo: [],
      },
      {
        info: "支持主流目标检测和图像识别算法",
        subInfo: [],
      },
      {
        info: "云端算法投送",
        subInfo: [],
      },
      {
        info: "4G、5G、Zigbee、LoRa",
        subInfo: [],
      },
      {
        info: "支持业内主流深度学习计算框架",
        subInfo: [],
      },
      {
        info: "支持Docker部署",
        subInfo: [],
      },
      {
        info: "任务编排",
        subInfo: [],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "4c40bf6d-c04e-4249-a032-e46e211www3c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "0e9040b9-1a64-4982-a246-91356ed928b9-01-cn",
      },
    },
  },
];

export var productData_en = [
  {
    id: 1,
    title: "Mini Embedded AI Coreboard",
    keyFeatures: ["Cube.AI", "Rich interfaces", "Small Footprint"],
    features: [
      {
        info: "Cortex-M4 series",
        subInfo: [],
      },
      {
        info: "Support deep learning frameworks such as Keras, TFLite, ONNX",
        subInfo: [],
      },
      {
        info: "On-board precise reference source 0.5%,VREF = 1.24V",
        subInfo: [],
      },
      {
        info: "FATFS file system",
        subInfo: [],
      },
      {
        info: "Support real-time operating systems FreeRTOS, RT-Thread,zephyr",
        subInfo: [],
      },
      {
        info: "Electromagnetic Immunity",
        subInfo: [],
      },
      {
        info: "RS-485, RS-232, I2C, USART, SPI, USB, I2S and other buses",
        subInfo: [],
      },
      {
        info: "Random number generation",
        subInfo: [],
      },
    ],
    name: "coreBoard",
    details: [
      {
        input: `<div>Powered by Vin pin, +5~12V</div> `,
        model: `STM32G431CBT6`,
        size: "43.2 mm * 19.06 mm",
        core: `ARM Cortex-M4`,
        flash: "128KB",
        RAM: "32KB | 22KB",
        max_freq: "170MHz",
        pn: "WAU2023022301 | WAU2023022301F",
      },
    ],
    img: [
      {
        src: prefix + "3c2b6713-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "ba14e59a-cff2-4627-99a5-19310964837b-01-en",
      },
    },
  },
  {
    id: "4g",
    title: "LoRa-4G Gateway",
    keyFeatures: [
      "Compact 75mm × 50mm",
      "MQTT cloud interconnection",
      "OpenCPU",
      "LoRa low and high frequency",
      "Ultra-low power design",
      "LoRa networking",
    ],
    features: [
      {
        info: "Flexible configuration locally and cloud",
        subInfo: [],
      },
      {
        info: "Positioning: BeiDou/GPS/GLONASS/QZSS",
        subInfo: [],
      },
      {
        info: "Embedded real-time operating system",
        subInfo: [],
      },
      {
        info: "Support RS-485, RS-232, UART, I2C, SPI, USB2.0, Analogue Audio, Digital Audio, ADC",
        subInfo: [],
      },
      {
        info: "Supports OTA",
        subInfo: [],
      },
      {
        info: "Support for on-screen display",
        subInfo: [],
      },
      {
        info: "Support SD card local storage",
        subInfo: [],
      },
    ],
    name: "gateway",
    details: [
      {
        input: `<div>5～12V power supply</div><div>Lithium battery power supply</div>`,
        size: "75mm × 50mm × 1.6mm",
        flash: "8MB",
        fre: "470M～510MHz and 860～930MHz",
      },
    ],
    img: [
      {
        src: prefix + "3c2bwe13-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "ba14e59a-cff2-6666-99a5-19310964837b-01-en",
      },
    },
  },
  {
    id: 2,
    title: "Small IoT Terminal Board",
    keyFeatures: [
      "WIFI and Bluetooth",
      "Built-in six-axis acceleration、temperature and humidity sensors",
      "USB Type-C",
    ],
    features: [
      {
        info: "Main module: ESP32 series",
        subInfo: [],
      },
      {
        info: "Supports multiple bus protocols,such as UART, I2C, SPI",
        subInfo: [],
      },
      {
        info: "OTA",
        subInfo: [],
      },
      {
        info: "Burning program via USB Type-C interface",
        subInfo: [],
      },
      {
        info: "Rich GPIO interfaces, a total of 26",
        subInfo: [],
      },
      {
        info: "Can be used for Web Server",
        subInfo: [],
      },
      {
        info: "Real-time operating system FreeRTOS",
        subInfo: [],
      },
      {
        info: "Good PCB design makes the antenna Optimal transmit and receive performance",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [
      {
        input: `<div>Methods 1: USB Type-C power supply</div>   
        <div>Methods 2: Vin pin 5V power supply</div>   `,
        model: `ESP32-WROOM-32`,
        flash: "4MB",
        SRAM: "520KB",
        pn: "WAU2022120901 | WAU2022120901F",
      },
    ],
    img: [
      {
        src: prefix + "a9c6405d-d11e-438b-8ab7-c29be2cfa51c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "1c98b919-e5bb-4023-8f66-d77078708372-01-en",
      },
    },
  },
  {
    id: 10,
    title: '"Opportunity" IoT terminal core boards',
    keyFeatures: [
      "powerful processing capabilities",
      "Rich IO interface (136pins)",
      "Embedded IoT communication board",
    ],
    features: [
      {
        info: "Cortex-M4 series",
        subInfo: [],
      },
      {
        info: "Support Keras, TFLite, ONNX and other deep learning frameworks",
        subInfo: [],
      },
      {
        info: "Support TFT screen",
        subInfo: [],
      },
      {
        info: "Real-time operating system FreeRTOS,RT-Thread,zephyr",
        subInfo: [],
      },
      {
        info: "Supports WIFI",
        subInfo: [],
      },
      {
        info: "Rich IO interface (136pins)",
        subInfo: [],
      },
      {
        info: "Support RS-485, USART, I2C, SPI, USB and other buses.",
        subInfo: [],
      },
      {
        info: "7.2Msps ADC",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [
      {
        input: `<div>+3.3V~+12V</div> `,
        // model: `ESP32-WROOM-32`,
        flash: "1MB | 4Gb | 256Mb",
        RAM: "(256KB + 4KB) 256Mb",
        pn: "WAU2022120901 | WAU2022120901F",
        core: "ARM Cortex-M4",
        max_freq: "180MHz",
      },
    ],
    img: [
      {
        src: prefix + "a9c64123-d11e-438b-8ab7-c29be2cfa52a-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "666040b9-1a64-4982-a246-91356ed928b9-01-en",
      },
    },
  },

  {
    id: 3,
    title: "Intelligent IOT Console System",
    keyFeatures: ["AI", "Digital Twins ", "Augmented Reality"],
    features: [
      {
        info: "The organic combination of edge computing and cloud computing, AI algorithms to achieve reasoning and decision-making.",
        subInfo: [],
      },
      {
        info: "Cloud algorithm delivery,on-demand dynamic distribution, to help edge AI.",
        subInfo: [],
      },
      {
        info: "Digital twin, support for zooming in, zooming out, rotating and projecting 3D models.",
        subInfo: [],
      },
      {
        info: "Database clustering",
        subInfo: [],
      },
      {
        info: "Automated testing to optimise product quality",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [
      {
        input: `方式一:USB Type-C供电; 方式二:Vin引脚5V供电`,
        model: `ESP32-WROOM-32`,
        flash: "4MB",
        SRAM: "520KB",
      },
    ],
    img: [
      {
        src: prefix + "c747d053-0baf-425e-8202-a72999054e7e-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "4b663419-4077-479f-9334-bf159509ecac-01-en",
      },
    },
  },
  {
    id: 4,
    title: "Digital Skyworks-Smart Factory",
    keyFeatures: [
      "Combination of reality and imagination",
      "Digital Interpretation",
      "AI Empowerment",
    ],
    features: [
      {
        info: "Combination of reality and imagination",
        subInfo: [],
      },
      {
        info: "Digital Interpretation",
        subInfo: [],
      },
      {
        info: "AI Empowerment",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "5d654ae2-8877-493e-b47e-be02d9f67a3b-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "379987fc-3663-46b2-a191-22d6d7b517c8-01-en",
      },
    },
  },
  ///new !
  {
    id: 5,
    title: "4G Mini-Terminal",
    keyFeatures: ["4.5cm × 4.5cm", "Cloud Interconnection", "OpenCPU"],
    features: [
      // {
      //   info: "Size:3cm × 3cm",
      //   subInfo: [],
      // },
      {
        info: "LTE Cat.1",
        subInfo: [],
      },
      {
        info: "Real-time operating systems",
        subInfo: [],
      },
      {
        info: "Firmware Over-the-Air",
        subInfo: [],
      },
      {
        info: "Positioning: BeiDou/GPS/GLONASS/QZSS",
        subInfo: [],
      },
      {
        info: "Support RS-485, RS-232, UART, IC, SPI, USB2.0, analogue audio, digital audio",
        subInfo: [],
      },
      {
        info: "Frequency band",
        subInfo: ["LTE-TDD：B34/B38/B39/B40/B41", "LTE-FDD：B1/B3/B5/B8"],
      },
      {
        info: "Data transfer",
        subInfo: [
          "LTE-TDD Rate：Max.7.5Mbps(DL)/Max.1Mbps(UL)",
          "LTE-FDD Rate：Max. 10Mbps(DL)/Max. 5Mbps(UL)",
        ],
      },
      {
        info: "Network protocols：MQTT / MQTTS / HTTP / HTTPS / TCP / UDP / PPP / FTP / NTP / PING / SSL",
        subInfo: [],
      },
    ],
    name: "小型4G终端",
    details: [
      {
        input: `<div style="text-align:center">5-12V power supply</div>`,
        size: `45mm * 45mm * 9mm`,
        RAM: "8MB",
        ROM: "8MB",
        file: "About 1408KB",
        pn: "WAU2023051301 | WAU2023051302F",
      },
    ],
    img: [
      {
        src: prefix + "52672361-76cf-487a-9889-8f7f80ee1b3c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "163b6afa-a371-49c4-86de-48fafc847536-01-en",
      },
    },
  },
  {
    id: 6,
    title: "NB-IoT Micro-Terminal",
    keyFeatures: ["2.5cm × 2.5cm", "Cloud Interconnection", "Ultra-low power"],
    features: [
      {
        info: "5G NB-IoT",
        subInfo: [],
      },
      {
        info: "MCU：STM32L series",
        subInfo: [],
      },
      {
        info: "Cube.AI",
        subInfo: [],
      },
      {
        info: `Keras, TFLite, ONNX and more
Deep learning frameworks`,
        subInfo: [],
      },
      {
        info: "Real-time operating systems",
        subInfo: [],
      },
      {
        info: "RS-485、I C、SPI、ADC etc.",
        subInfo: [],
      },
      {
        info: "Firmware over-the-air upgrade",
        subInfo: [],
      },
      {
        info: "Frequency band：B5/B8",
        subInfo: [],
      },
      {
        info: "Data transfer：Cat NB2 Rate：126kbps（DL）/ 158.5kbps（UL）",
        subInfo: [],
      },
      {
        info: "Network protocols：MQTT / HTTP / TCP / UDP / CoAP / LwM2M / IPV6 / DTLS / DNS",
        subInfo: [],
      },
    ],
    name: "微型NB-IoT终端",
    details: [
      {
        input: `<div>Method 1: 5V power supply</div>   
<div>Method 2: Lithium battery powered</div>  `,
        fre: "B5/B8",
        size: `25mm * 25mm * 6mm`,
        core: "Cortex-M0+",
        flash: "32KB",
        RAM: "8KB",
        maxPin: "32MHz",
        eeprom: "1KB",
        pn: "WAU2023060601 | WAU2023060602F",
      },
    ],
    img: [
      {
        src: prefix + "1438f644-006e-46b6-b644-5078ae4122c3-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "e701af55-15a7-4aab-bbcf-5c6031412fcc-01-en",
      },
    },
  },
  {
    id: 7,
    title: "LoRa Micro-Terminal",
    keyFeatures: ["3.5cm × 3.5cm", "Ultra-low power", "LoRa networking"],
    features: [
      {
        info: "Frequency bands:470MHz to 510MHz",
        subInfo: [],
      },
      {
        info: "Cortex-M0+ series",
        subInfo: [],
      },
      {
        info: "Cube.AI",
        subInfo: [],
      },
      {
        info: `Keras, TFLite, ONNX etc.Deep learning frameworks`,
        subInfo: [],
      },
      {
        info: "Real-time operating systems",
        subInfo: [],
      },
      {
        info: "RS-485、I C、USART、ADC etc",
        subInfo: [],
      },
      {
        info: "Firmware over-the-air upgrade",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [
      {
        input: `<div>Method 1: 5~12V power supply</div> 
<div>Method 2: Lithium battery powered</div> `,
        size: `35mm × 35mm × 13.7mm`,
        core: "Cortex-M0+",
        flash: "32KB",
        RAM: "8KB",
        maxPin: "32MHz",
        eeprom: "1KB",
        pn: "WAU2023042601 | WAU2023042601F",
      },
    ],
    img: [
      {
        src: prefix + "1e9b6567-f715-4db9-bc4e-4f443ba6e6a8-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "58e893f1-dd71-40a2-8f84-5a11e4c0afee-01-en",
      },
    },
  },
  //机器视觉
  {
    id: 8,
    title: "Security Applications",
    keyFeatures: [],
    features: [
      {
        info: "Safety Helmet/Vest Detection",
        subInfo: [],
      },
      {
        info: "Flame Detection",
        subInfo: [],
      },
      {
        info: "Face Detection",
        subInfo: [],
      },
      {
        info: "Person Detection",
        subInfo: [],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "46fa6615-ab74-4df3-a6c4-3050338244e6-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "",
      },
    },
  },
  {
    id: 9,
    title: "Industrial Inspection",
    keyFeatures: [],
    features: [
      {
        info: "Cosmetic Defects Detection",
        subInfo: ["High Precision", "Stability", "Real-Time Application"],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "0e16b67f-853e-4571-9139-123231f4298f-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "",
      },
    },
  },
];

export var serviceData_en = [
  {
    id: 1,
    title: "Circuit Design",
    keyFeatures: [],
    features: [
      {
        info: "Analog/Digital Circuit Design, PCB Layout, Prototyping Production, including:",
        subInfo: [
          "Embedded System (Including communication module)",
          "AC/DC power supply, DC/DC power supply",
          "Lithium battery energy storage system",
        ],
      },
      {
        info: "3D system modeling",
        subInfo: [],
      },
      {
        info: "Cost-effective, mass-producible BOM materials",
        subInfo: [],
      },
      {
        info: "Third party certification services(EMC, Safety and Reliability)",
        subInfo: [],
      },
      {
        info: "High density, compact design",
        subInfo: [],
      },
    ],
    name: "dianlu",
    details: [],
    img: [
      {
        src: prefix + "5b46c30d-4b0b-410b-b824-1281e3112aba-03-xx.jpg",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-en",
      },
    },
  },
  {
    id: 2,
    title: "Embedded Software Development",
    keyFeatures: [],
    features: [
      {
        info: "ARM Cortex-M series (e.g., STM32), including：",
        subInfo: [
          "ADC/DAC",
          "UART,I2C,SPI,CAN,RS-232,RS-485,U SB and other bus communication",
          "Real-time operating system FreeRTOS, RT-Thread, etc.",
          "FATFS file system",
        ],
      },
      {
        info: "ARM Cortex-A Series",
        subInfo: [],
      },
      {
        info: "Battery BMS algorithm",
        subInfo: [],
      },
      {
        info: "Lightweight deep neural network development",
        subInfo: [],
      },
      {
        info: "Over-the-Air Firmware Upgrade(FOTA)",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [],
    img: [
      {
        src: prefix + "6faa16f6-bf6f-483d-afc1-6c39ef1261a9-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-en",
      },
    },
  },
  {
    id: 3,
    title: "Mechanical design",
    keyFeatures: [],
    features: [
      {
        info: "ID Design",
        subInfo: [],
      },
      {
        info: "Complete machine design",
        subInfo: [],
      },
      {
        info: "2D/3D structural drawings",
        subInfo: [],
      },
      {
        info: "Stacking",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "c747d053-0baf-425e-8202-a72999054e6e-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-en",
      },
    },
  },
  {
    id: 4,
    title: "Desktop Application Development",
    keyFeatures: [
      "Cross Platform",
      "Multiple Communication Protocols BLE, WIFI, etc.",
      "Real-time Waveform Graph",
    ],
    features: [
      {
        info: "Cross-platform (Windows, Linux, MacOs)",
        subInfo: [],
      },
      {
        info: "AI Algorithm",
        subInfo: [],
      },
      {
        info: "Free addition and subtraction of modules",
        subInfo: [],
      },
      {
        info: "Adaptive Layout",
        subInfo: [],
      },
      {
        info: "Multiple Communication Protocols BLE, WIFI",
        subInfo: [],
      },
      {
        info: "Embedded Database",
        subInfo: [],
      },
      {
        info: "Exquisite UI Design",
        subInfo: [],
      },
      {
        info: "Automatic Report Generation ",
        subInfo: [],
      },
      {
        info: "OTA Upgrade",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "777c112b-83ca-4acf-8a98-4947ab5524da-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "91dc746c-3c01-437e-a6e1-e1ddc932d9ce-01-en",
      },
    },
  },
  {
    id: 5,
    title: "Front-end Development",
    keyFeatures: [
      "Front-end Development",
      "Big Data Screen",
      "Professional UI Design",
    ],
    features: [
      {
        info: "Real-time",
        subInfo: [],
      },
      {
        info: "Omni-directional",
        subInfo: [],
      },
      {
        info: "GIS positioning",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "7d0dd728-23fa-4e01-9e94-234809b8be78-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "a73372b9-7df0-46bc-b989-00131aa87380-01-en",
      },
    },
  },
  {
    id: 6,
    title: "Data Analysis",
    keyFeatures: [],
    features: [
      {
        info: "Mathematical Statistics",
        subInfo: [],
      },
      {
        info: "Data Modeling",
        subInfo: [],
      },
      {
        info: "Data Mining",
        subInfo: [],
      },
      {
        info: "Machine Learning",
        subInfo: [],
      },
      {
        info: "Big Data Analysis",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "390147f2-6115-42d6-9be8-0300ddb473ef-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "a73372b9-7df0-46bc-b989-00131aa87380-01-cn",
      },
    },
  },
  {
    id: 7,
    title: "Edge Computing Gateway",
    keyFeatures: [
      "AI computing",
      "Edge-cloud Collaboration",
      "High Reliability",
    ],
    features: [
      {
        info: "AI algorithm",
        subInfo: [],
      },
      {
        info: "Linux system",
        subInfo: [],
      },
      {
        info: "Support mainstream target detection and image recognition algorithms",
        subInfo: [],
      },
      {
        info: "Algorithmic deliveried from Cloud",
        subInfo: [],
      },
      {
        info: "4G、5G、Zigbee、LoRa",
        subInfo: [],
      },
      {
        info: "Supports the industry's leading deep learning computing frameworks",
        subInfo: [],
      },
      {
        info: "Support for Docker Deployment",
        subInfo: [],
      },
      {
        info: "Task scheduling",
        subInfo: [],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "4c40bf6d-c04e-4249-a032-e46e211www3c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "0e9040b9-1a64-4982-a246-91356ed928b9-01-en",
      },
    },
  },
];

export const getProductByName = function (name, en_or_cn = "cn") {
  if (en_or_cn === "cn")
    return productData.find((element) => element.title === name);
  if (en_or_cn === "en")
    return productData_en.find((element) => element.title === name);
};
