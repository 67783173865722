import { createRouter, createWebHashHistory } from "vue-router";
import { serviceData } from "@/until/productdata";
const seriveMenu = [
  {
    title: "navMenu.product.title",
    child: [
      {
        title: "navMenu.product.child1.title",
        to: "/productAndService/hardware",

        child: [
          {
            title: "navMenu.product.child1.child.title7",
            to: "/productAndService/hardware?id=4g",
          },
          {
            title: "navMenu.product.child1.child.title3",
            to: "/productAndService/hardware?id=0",
          },
          {
            title: "navMenu.product.child1.child.title4",
            to: "/productAndService/hardware?id=1",
          },
          {
            title: "navMenu.product.child1.child.title5",
            to: "/productAndService/hardware?id=2",
          },
          {
            title: "navMenu.product.child1.child.title6",
            to: "/productAndService/hardware?id=10",
          },
          {
            title: "navMenu.product.child1.child.title1",
            to: "/productAndService/hardware?id=3",
          },
          {
            title: "navMenu.product.child1.child.title2",
            to: "/productAndService/hardware?id=4",
          },
        ],
      },

      {
        title: "navMenu.product.child4.title",
        to: "/productAndService/vision",
        child: [
          {
            title: "navMenu.product.child4.child.title1",
            to: "/productAndService/vision?id=0",
          },
          {
            title: "navMenu.product.child4.child.title2",
            to: "/productAndService/vision?id=1",
          },
        ],
      },
      {
        title: "navMenu.product.child2.title",
        to: "/productAndService/software",
        child: [
          {
            title: "navMenu.product.child2.child.title1",
            to: "/productAndService/software?id=0",
          },
        ],
      },
      {
        title: "navMenu.product.child3.title",
        to: "/productAndService/digtaltwins",
        child: [
          {
            title: "navMenu.product.child3.child.title1",
            to: "/productAndService/digtaltwins?id=0",
          },
        ],
      },
    ],
  },
  {
    title: "navMenu.serive.title",
    child: [
      {
        title: "navMenu.serive.child1.title",
        to: "/productAndService/electronic",
        child: [
          {
            title: "navMenu.serive.child1.child.title1",
            to: "/productAndService/electronic?id=0",
          },
          {
            title: "navMenu.serive.child1.child.title2",
            to: "/productAndService/electronic?id=1",
          },
          {
            title: "navMenu.serive.child1.child.title3",
            to: "/productAndService/electronic?id=2",
          },
        ],
        // data:[serviceData[0],serviceData[1],serviceData[2]]
      },
      {
        title: "navMenu.serive.child2.title",
        to: "/productAndService/app",

        child: [
          {
            title: "navMenu.serive.child2.child.title1",
            to: "/productAndService/app?id=0",
          },
        ],
        // data:[serviceData[3]]
      },
      {
        title: "navMenu.serive.child3.title",
        to: "/productAndService/frontEnd",

        child: [
          {
            title: "navMenu.serive.child3.child.title1",
            to: "/productAndService/frontEnd?id=0",
          },
        ],
        // data:[serviceData[4]]s
      },
      {
        title: "navMenu.serive.child4.title",
        to: "/productAndService/dataAnla?id=0",

        // data:[serviceData[4]]s
      },
      // {
      //   title: "navMenu.serive.child5.title",
      //   to: "/productAndService/gateWay?id=0",
      //   // data:[serviceData[4]]s
      // },
    ],
  },
];

const supportMenu = [
  {
    title: "navMenu.support.title",

    child: [
      {
        title: "navMenu.support.child.title",
        to: "/support",
        child: [{ title: "navMenu.support.child.child.title", to: "/support" }],
      },
    ],
  },
];

const routes = [
  {
    //主页
    path: "/",
    name: "home",
    component: () => import("@/views/index"),
    meta: { title: "message.home", showInNav: true, hasMenu: false },
  },
  // {
  //   //英文主页
  //   path: "/en",
  //   name: "en_home",
  //   component: () => import("@/views/en"),
  //   meta: { title: "message.home", showInNav: true, hasMenu: false },
  // },

  {
    //产品与服务
    // path: "/product",
    path: "/productAndService",
    name: "productAndService",
    redirect: "/productAndService/electronic",
    component: () => import("@/views/productAndService"),
    children: [
      {
        path: "electronic",
        name: "Electronic",
        meta: {},
        component: () => import("@/views/productAndService/electronic/"),
      },
      {
        path: "app",
        name: "app",
        meta: {},
        component: () => import("@/views/productAndService/app/"),
      },
      {
        path: "frontEnd",
        name: "frontEnd",
        meta: {},
        component: () => import("@/views/productAndService/frontEnd/"),
      },
      {
        path: "hardware",
        name: "hardware",
        meta: {},
        component: () => import("@/views/productAndService/hardware/"),
      },
      {
        path: "software",
        name: "software",
        meta: {},
        component: () => import("@/views/productAndService/software/"),
      },
      {
        path: "digtaltwins",
        name: "digtaltwins",
        meta: {},
        component: () => import("@/views/productAndService/digtaltwins/"),
      },
      {
        path: "vision",
        name: "vision",
        meta: {},
        component: () => import("@/views/productAndService/vision/"),
      },
      {
        path: "dataAnla",
        name: "dataAnla",
        meta: {},
        component: () => import("@/views/productAndService/dataAnla/"),
      },
      {
        path: "gateway",
        name: "gateway",
        meta: {},
        component: () => import("@/views/productAndService/gateway/"),
      },
    ],
    meta: {
      title: "message.product",
      showInNav: true,
      hasMenu: true,
      menu: seriveMenu,
    },
  },
  {
    //最新资讯
    path: "/news/",
    name: "news",
    component: () => import("@/views/news"),
    meta: { title: "message.news", showInNav: true, hasMenu: false },
  },
  {
    //支持
    path: "/support",
    name: "support",
    component: () => import("@/views/support"),
    meta: {
      title: "message.support",
      showInNav: true,
      hasMenu: true,
      menu: supportMenu,
    },
  },
  {
    //关于我们
    path: "/about",
    name: "about",
    component: () => import("@/views/about"),
    meta: { title: "message.about", showInNav: true, hasMenu: false },
    // children: [
    //   {
    //     path: 'aboutus',
    //     // component: UserProfile,
    //     meta: { title: "公司简介" },
    //   },
    //   {
    //     path: 'joinus',
    //     // component: UserPosts,
    //     meta: { title: "加入我们" },
    //   },
    // ],
  },

  {
    //联系我们
    path: "/contact",
    name: "contact",
    component: () => import("@/views/contact"),
    meta: { title: "message.contact", showInNav: true, hasMenu: false },
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("@/views/login"),
  //   meta: {
  //     title: "登录",
  //     showInNav: false,
  //   },
  // },
  // {
  //   // 个人中心
  //   path: "/usercenter",
  //   name: "usercenter",
  //   component: () => import("@/views/userCenter"),
  //   meta: {
  //     title: "个人中心",
  //     showInNav: false,
  //   },
  // },
  // {
  //   // 搜索结果
  //   path: "/search",
  //   name: "search",
  //   component: () => import("@/views/search"),
  //   meta: {
  //     title: "搜索结果",
  //     showInNav: false,
  //   },
  // },
  {
    //文章详情
    path: "/article/:id",
    name: "article",
    component: () => import("@/components/article/"),
    meta: { title: "文章", showInNav: false, hasMenu: false },
  },

  //这个*匹配必须放在最后，将该路由配置放到所有路由的配置信息的最后，否则会其他路由path匹配造成影响。
  {
    //404
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/404"),
    meta: { title: "404", showInNav: false, hasMenu: false },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    // console.log(to);
    const list = [
      "Electronic",
      "app",
      "frontEnd",
      "hardware",
      "software",
      "digtaltwins",
    ];
    //set 的has方法
    const set = new Set(list);
    const has = set.has(to.name);
    //has === true

    if (!has) return { top: 0 };
  },
});

// router.beforeEach((to, from, next) => {
//   to.meta.title && (document.title = to.meta.title);
//   next();
// });

export default router;
